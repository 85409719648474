<template>
  <div class="contain">
    <div>
      <el-page-header @back="goBack" content="赛事查看">
      </el-page-header>
    </div>
    <div class="message-form">
      <div class="form-box">
        <span style="margin-right: 20px">比赛名称 :</span>
        <el-input style="width: 500px" v-html="obj.eventName?obj.eventName:''" placeholder="请输入比赛名称"
                  size="small"></el-input>
      </div>
      <div class="form-box">
        <span style="margin-right: 20px">比赛简介 :</span>
        <el-input style="width: 500px" v-html="obj.eventDesc" type="textarea" :rows="3"
                  placeholder="请输入比赛简介" size="small"></el-input>
      </div>
      <div class="form-box">
        <span style="margin-right: 20px">赛事封面 :</span>
        <img :src="obj.eventCover" class="avatar">
      </div>
      <div class="form-box">
        <span style="margin-right: 20px">评分标准 :</span>
        <el-radio-group disabled v-model="obj.scoreStandard">
          <el-radio :label="1">广场舞评分</el-radio>
          <el-radio :label="2">健身气功评分</el-radio>
          <el-radio :label="3">气舞评分</el-radio>
          <el-radio :label="4">其他评分</el-radio>
        </el-radio-group>
      </div>
      <div class="choose-box" v-show="obj.scoreStandard === 1">
        <p style="color:#999;">说明：管理员设置分数上限,裁判根据分数上限进行打分,打分完毕后,系统平均分为参赛者得分</p>
        <div style=" display: flex;align-items: center;margin-top: 20px" class="add-box">
          <span style="color: #999;margin-right: 10px">去掉最高分最低分 : </span>
          <el-radio-group v-model="obj.subAddScore" disabled>
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </div>
        <div style=" display: flex;align-items: center;margin-top: 20px">
          <span style="color: #999;margin-right: 10px">分数上限 : </span>
          <el-input v-model="obj.maxScore" disabled style="width: 300px" placeholder="请输入分数上限"
                    size="small"></el-input>
        </div>
      </div>
      <div class="choose-box" v-show="obj.scoreStandard === 2">
        <p style="color:#999;">
          说明：管理员设置AB组分数上限,裁判根据分数上限进行打分,打分完毕后,系统分别去AB组的平均分之和为参赛者得分</p>
        <div style=" display: flex;align-items: center;margin-top: 20px" class="add-box">
          <span style="color: #999;margin-right: 10px">去掉最高分最低分 : </span>
          <el-radio-group v-model="obj.subAddScore" disabled>
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </div>
        <div style=" display: flex;align-items: center;margin-top: 20px">
          <span style="color: #999;margin-right: 10px">A组分数上限&#12288; : </span>
          <el-input v-model="obj.maxScoreA" disabled style="width: 300px" placeholder="请输入分数上限"
                    size="small"></el-input>
        </div>
        <div style=" display: flex;align-items: center;margin-top: 20px">
          <span style="color: #999;margin-right: 10px">B组分数上限&#12288; : </span>
          <el-input v-model="obj.maxScoreB" disabled style="width: 300px" placeholder="请输入分数上限"
                    size="small"></el-input>
        </div>
      </div>
      <div class="choose-box" v-show="obj.scoreStandard === 3">
        <p style="color:#999;">
          说明：管理员设置AB组分数上限,裁判根据分数上限进行打分,打分完毕后,系统按照A组占60%,B组占40%分别取AB组的平均分之和为参赛者得分</p>
        <div style=" display: flex;align-items: center;margin-top: 20px" class="add-box">
          <span style="color: #999;margin-right: 10px">去掉最高分最低分 : </span>
          <el-radio-group v-model="obj.subAddScore" disabled>
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </div>
        <div style=" display: flex;align-items: center;margin-top: 20px;color: #999">
          <span style="margin-right: 10px">A组分数上限&#12288; : </span>
          <el-input v-model="obj.maxScoreA" disabled style="width: 300px" placeholder="请输入分数上限"
                    size="small"></el-input>
        </div>
        <div style=" display: flex;align-items: center;margin-top: 20px">
          <span style="color: #999;margin-right: 10px">B组分数上限&#12288; : </span>
          <el-input v-model="obj.maxScoreB" disabled style="width: 300px" placeholder="请输入分数上限"
                    size="small"></el-input>
        </div>
      </div>
      <div class="choose-box" v-show="obj.scoreStandard === 4">
        <p style="color:#999;">
          说明：管理员设置分数上限,裁判根据分数上限进行打分,打分完毕后,系统取平均分或总分平均分为参赛者得分</p>
        <div style=" display: flex;align-items: center;margin-top: 20px" class="add-box">
          <span style="color: #999;margin-right: 10px">去掉最高分最低分 : </span>
          <el-radio-group v-model="obj.subAddScore" disabled>
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </div>
        <div style=" display: flex;align-items: center;margin-top: 20px" class="add-box">
          <span style="color: #999;margin-right: 10px">计分方式 : </span>
          <el-radio-group v-model="obj.scoreStyle" disabled>
            <el-radio :label="1">总分</el-radio>
            <el-radio :label="0">平均分</el-radio>
          </el-radio-group>
        </div>
        <div style=" display: flex;align-items: center;margin-top: 20px">
          <span style="color: #999;margin-right: 10px">单个分数上限 : </span>
          <el-input v-model="obj.maxScore" style="width: 300px" disabled placeholder="请输入分数上限"
                    size="small"></el-input>
        </div>
      </div>
    </div>

    <div class="contain-title">
      <h3>参赛选手</h3>
    </div>
    <div class="contain-table">
      <el-table
          :data="obj.playerList"
          :header-cell-style="{ background:'#b14040',color:'#fff'}"
          border
          style="width: 100%">
        <el-table-column label="姓名" align="center">
          <template slot-scope="scope">
            <span style="color: #b14040">{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="头像" align="center">
          <template slot-scope="scope">
            <img v-if="scope.row.avatarUrl" :src="scope.row.avatarUrl"
                 style="width: 50px;height: 50px;border-radius: 50%"
                 alt="">
            <el-avatar style="width: 50px;height: 50px" v-else
                       :src="avatarUrl"></el-avatar>
          </template>
        </el-table-column>
        <el-table-column prop="descContent" label="简介" show-overflow-tooltip align="center">
        </el-table-column>
      </el-table>
    </div>

    <div class="contain-title">
      <h3>裁判</h3>
    </div>
    <div class="contain-table">
      <el-table
          :data="obj.judgeList"
          :header-cell-style="{ background:'#b14040',color:'#fff'}"
          border
          style="width: 100%">
        <el-table-column label="裁判编号" align="center">
          <template slot-scope="scope">
            <span style="color: #b14040">{{ scope.row.judgeCode }}</span>
          </template>
        </el-table-column>
        <el-table-column label="裁判姓名" align="center">
          <template slot-scope="scope">
            <span style="color: #b14040">{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="裁判头像" align="center">
          <template slot-scope="scope">
            <img v-if="scope.row.avatarUrl" :src="scope.row.avatarUrl"
                 style="width: 50px;height: 50px;border-radius: 50%"
                 alt="">
            <el-avatar style="width: 50px;height: 50px" v-else
                       :src="avatarUrl"></el-avatar>
          </template>
        </el-table-column>
        <el-table-column prop="descContent" label="简介" show-overflow-tooltip align="center">
        </el-table-column>
        <el-table-column label="裁判类型" align="center">
          <template slot-scope="scope">
            <!--            <span>{{ scope.row.mainStatus === 1 ? '主裁判' : '普通裁判' }}</span>-->
            <el-tag
                type="danger"
                :effect="scope.row.mainStatus === 1 ?'dark':'plain'">
              {{ scope.row.mainStatus === 1 ? '主裁判' : '普通裁判' }}
            </el-tag>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer-btn">
      <el-button style="width: 150px; background-color: #bf353b;color: #fff" @click="goBack">返 回</el-button>
    </div>

  </div>
</template>

<script>
import {findById} from "../../api/event";

export default {
  name: "eventDetail",
  data() {
    return {
      id: null,
      obj: null,
      avatarUrl: require("@/assets/images/avatar.png")
    }
  },
  created() {
    this.id = this.$route.query.id
    this.findById(this.id)
  },
  methods: {
    goBack() {
      this.$router.push('/eventList')
    },
    async findById(id) {
      const {data} = await findById(id)
      if (data.code === 200) {
        this.obj = data.data
      }
    }
  }
}
</script>

<style scoped lang="less">
.contain {
  /*margin: 0px;*/
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 10px 50px;
  box-sizing: border-box;
  background: url("../../assets/images/homeBanner.png") no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;

  .dialog-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .dialog-title {
      margin-top: 20px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      /deep/ .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
      }

      .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
      }

      .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 80px;
        height: 80px;
        line-height: 80px;
        text-align: center;
      }

      .avatar {
        width: 80px;
        height: 80px;
        display: block;
      }
    }
  }

  .message-form {
    width: 60%;
    margin: 20px auto;
    box-sizing: border-box;

    .form-box {
      margin: 20px 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      /deep/ .el-radio__input.is-checked .el-radio__inner {
        border-color: #bf353b;
        background-color: #bf353b;
      }

      /deep/ .el-radio__input.is-checked + .el-radio__label {
        color: #bf353b;
      }

      /deep/ .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
      }

      .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
      }

      .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 100px;
        height: 100px;
        line-height: 100px;
        text-align: center;
      }

      .avatar {
        width: 100px;
        height: 100px;
        display: block;
      }
    }

    .choose-box {
      width: 100%;
      padding: 10px 50px;
      box-sizing: border-box;
    }
  }

  .contain-title {
    width: 100%;
    margin: 20px 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .contain-table {
    width: 100%;
  }

  .footer-btn {
    width: 100%;
    margin: 50px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
